export const WEEKDAYNAMES = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
export const WEEKDAYBARENAMES = ["Hoje", "Amanhã"];
export const BRCalendarLocale = {
    sunday: "DOM",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "OK",
    today: "HOJE",
    yesterday: "ONTEM",
    hours: "HORAS",
    minutes: "MINUTOS",
    seconds: "SEGUNDOS",
    formattedMonthPattern: "MM/yyyy",
    formattedDayPattern: "dd/MM/yy",

    // for DateRangePicker
    last7Days: "Última semana",
};

export function isReserveDateEqual(date1: Date, date2: Date) {
    if (date1.getTime() === date2.getTime()) {
        // if (isSameYear(date1, date2) && isSameMonth(date1, date2) && isSameDay(date1, date2)) {

        return true
    }
}

export function isSameMonth(date1: Date, date2: Date) {
    return date1.getMonth() === date2.getMonth()
}
export function isSameDay(date1: Date, date2: Date) {
    return date1.getDay() === date2.getDay()
}
export function isSameYear(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear()
}